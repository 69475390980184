import React, { forwardRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/react-hooks';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import Loading from '../../../Common/Loading';
import { Margin } from '../../../Common/styled/Margins';
import { Spacer } from '../../../Common/styled/Spacer';
import { Row, Column } from '../../../Common/styled/Groups';
import { Form } from '../../../Common/styled/Form';
import { GET_HOSPITAL_GROUPS_FOR_PREVIEW } from '../../../../graphql/queries';
import { CREATE_USER_GROUP } from '../../../../graphql/mutations';
import { parseGraphqlErrors } from '../../../../utils/FormikUtils';
import { TextFieldUI } from '../../../../componentsUI/TextField';
import { AlertUI } from '../../../../componentsUI/Alert';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 15,
      width: '100%',
      maxWidth: 800,
    },
    '& .MuiTypography-h6': {
      fontWeight: 600,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
  label: {
    color: alpha(theme.palette.text.primary, 0.54),
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
}));

export const NewGroupDialog = ({ open, onClose }) => {
  const { t } = useTranslation();

  const uuid = useSelector((state) => state.hospital.uuid);

  const [updating, setUpdating] = useState(false);
  const [serverError, setServerError] = useState(false);
  const classes = useStyles();

  const [createUserGroup, { loading }] = useMutation(CREATE_USER_GROUP, {
    onCompleted: ({ createUserGroup: result }) => {
      toast(`Group ${result.name} has been created.`, { className: 'toast-success' });
      onClose();
    },
    onError: (createUserGroupError) => {
      if (createUserGroupError.graphQLErrors && createUserGroupError.graphQLErrors.length) {
        setServerError(createUserGroupError.graphQLErrors[0].message);
      }
      toast(`There was an error creating group ${'name'}. Please try again.`, { className: 'toast-error' });
    },
    refetchQueries: [{ query: GET_HOSPITAL_GROUPS_FOR_PREVIEW, variables: { uuid } }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async (values, { setErrors, resetForm }) => {
    setServerError(false);
    setUpdating(true);
    try {
      await createUserGroup({
        variables: {
          input: {
            name: values.name,
            hospitalUuid: uuid,
          },
        },
      }).then(
        setUpdating(false),
      );
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (
        Object.keys(formikErrors).length === 0
        && formikErrors.constructor === Object
      ) {
        setServerError(true);
      } else {
        setErrors(formikErrors);
      }
    }
    resetForm({});
  };

  const closeModal = () => {
    setServerError(null);
    onClose();
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required.field')),
  });

  const initialValues = {
    name: '',
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>{t('add.group')}</DialogTitle>
      <DialogContent>
        {updating || loading ? (
          <Loading />
        ) : (
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <Form autoComplete="off" id="addHospitalGroup">
                <Margin mx-0>
                  <Row>
                    <Column>
                      <TextFieldUI
                        name="name"
                        label={t('group.name')}
                        props={props}
                      />
                    </Column>
                    <Spacer />
                    <Column />
                  </Row>
                </Margin>

                <Margin mx-0 mt-5 mb-4>
                  <Row>
                    {serverError ? <AlertUI severity="error" title="Error">{t('server.error')}</AlertUI> : null}
                  </Row>
                </Margin>
              </Form>
            )}
          </Formik>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          form="addHospitalGroup"
          type="reset"
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          form="addHospitalGroup"
          type="submit"
        >
          {t('add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
